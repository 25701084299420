.App {
    min-height: 100vh;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    // background-color: #232943;
}

.container-fluid {
    width: 100%;
    display: flex;
    max-width: 480px;
    flex-direction: column;
    background-image: url(../../../assets/images/background_register.jpg) !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    height: auto;
    // max-height: 100dvh;
    align-items: center;
    justify-content: center;
}

.container-fluid__register {
    width: 100%;
    display: flex;
    max-width: 480px;
    flex-direction: column;
    background-image: url(../../../assets/images/background_register.jpg) !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    height: auto;
    max-height: 100dvh;
    align-items: center;
    justify-content: center;
}

.container-fluid__login {
    width: 100%;
    display: flex;
    max-width: 480px;
    flex-direction: column;
    background-image: url(../../../assets/images/background_register.jpg) !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 20%;
    height: auto;
    max-height: 100dvh;
    align-items: center;
    justify-content: center;
}

.container {
    width: 100%;
    max-width: 480px;
    padding: 0 15px;
    display: flex;
}

.content {
    flex: 1;
    width: 100%;
    max-width: 480px;
    padding: 0 15px;
    padding-bottom: 70px;
    min-height: 115vh;
}

.content-two {
    flex: 1;
    width: 100%;
    max-width: 480px;
    padding: 0 15px;
    padding-bottom: 70px;
    min-height: 115vh;
}

.content-register {
    flex: 1;
    width: 100%;
    max-width: 480px;
    padding: 0 15px;
    padding-bottom: 70px;
    min-height: 100dvh;
}

.content-login {
    flex: 1;
    width: 100%;
    max-width: 480px;
    padding: 0 15px;
    padding-bottom: 70px;
    min-height: 100dvh;
}
