.navbar__footer {
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    left: 50%;
    transform: translate(-50%, 0%);
}

.navbar__footer--container {
    width: 100%;
    max-width: 480px;
}
