.bg-header-my {
    width: 1048px !important;
    height: 6rem;
    background: #bbb5e9;
    margin-left: -200px !important;
}

.my-header {
    background-image: url(../../assets/images/my_bg.png);
    position: absolute;
    top: 30px;
    height: 8rem;
}

.yh {
    width: 100%;
    height: 4rem;
    background-image: url(../../assets/images/my-bg-2.png);
    background-size: 100%;
    position: absolute;
    top: 97px;
}

.content-my {
    position: absolute;
    top: 123px;
}

.Notice_ul {
    width: 90%;
    border-radius: 0.266667rem;
    padding: 0.746667rem 0.4rem;
    background-image: linear-gradient(#f8f4f4, #fff);
    position: relative;
    top: -0.533333rem;
    left: 5%;
}

.nang-cap {
    height: 15rem;
    margin: 0 -15px;
    background-image: linear-gradient(left, #27a6fa, #8e71f5);
    background-image: -webkit-linear-gradient(left, #27a6fa, #8e71f5);
}

.header-nang-cap {
    position: absolute;
    top: 0px;
    width: 100%;
    margin: 0 -15px;
}

.img-bg-nang-cap {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}

.content-nang-cap {
    position: absolute;
    top: 90px;
    left: 20px;
}

.input-withdraw input {
    font-size: 16px;
    outline: none;
    border-radius: 35px;
    padding: 10px 15px !important;
    background-color: #fff;
    color: #000;
    border: 1px solid rgb(215, 215, 215) !important;
}

.input-withdraw input:focus {
    border: 2px solid #2e4fc5 !important;
}
